import { motion } from "framer-motion";
import Video from "../../assets/video/video1.webm";
import { infosCompOne } from "../../utils/myData";
import {SliderLogo} from "./slider/SliderLogo";
const ComponentOne = ({ id, bgcolor, color, customer }) => {
  // Find the unique element in the data
  const findInfos = infosCompOne.find((myid) => myid.id === id);
  return (
    <motion.article
      id="component-one"
      style={{
        background: bgcolor,
        color: color,
      }}
    >
      <div className="wrapper">
        <motion.div
          className="content"
          initial={{ opacity: 0, translateY: 100, rotateY: 20 }}
          whileInView={{ opacity: 1, translateY: 0, rotateY: 0 }}
          transition={{ type: "spring", ease: "easeIn" }}
        >
          <h2 className="title_h1" style={{ position: "relative" }}>
            {findInfos.title}
            <span className="trait"></span>
          </h2>
          {findInfos.text.map((item, idx) =>
            item.length < 1 ? (
              <motion.span
                initial={{ opacity: 0, translateY: -100 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                transition={{ duration: 0.2, ease: "easeOut" }}
                className="text"
                key={idx + 1}
              >
                {item}
              </motion.span>
            ) : (
              <p key={idx + 1} className="text mth">
                {item}
              </p>
            )
          )}

          <p className="mobaliparis" style={{ marginBottom: "3em" }}>
            <span>Mobali</span> <span>{findInfos.theme}</span>
          </p>

          {customer && <SliderLogo />}
        </motion.div>

        {id === 1 && (
          <motion.div
            className="videohome"
            initial={{ opacity: 0, translateY: 100, rotateY: -20 }}
            whileInView={{ opacity: 1, translateY: 0, rotateY: -20 }}
            transition={{ type: "spring", ease: "easeIn" }}
          >
            <video muted autoPlay loop playsInline>
              <source src={Video} type="video/webm" />
              Votre navigateur ne prend pas en charge la vidéo.
            </video>
          </motion.div>
        )}
      </div>
    </motion.article>
  );
};

export default ComponentOne;
