import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { FreeMode, Autoplay } from 'swiper/modules';

export const SliderLogo = () => {
    return (
        <>
            <Swiper
                slidesPerView={5}
                spaceBetween={30}

                autoplay={{
                    delay: 2500, // Délai en millisecondes entre chaque slide
                    disableOnInteraction: false, // Permet à l'utilisateur d'interagir sans désactiver l'autoplay
                }}

                modules={[FreeMode, Autoplay]}
                className="mySwiperLogo"
            >
                {logos.map((item, idx) => (

                    <SwiperSlide key={idx + 1}>
                        <div className="slide">
                            <figure>
                                <img src={`/uploads/${item}.webp`} alt={`lopgo de ${item}`}/>
                            </figure>
                        </div>
                    </SwiperSlide>

                ))}
            </Swiper>
        </>
    );
}

const logos = [
    "puma",
    "snipes",
    "universal",
    "cider",
    "bodyminute",
    "france",
    "moni",
    "boohoo",
    // "Shein",
    "sixthjune",
    "sony",
    "produitlaitiers",
    "aidemodial",
    // "milkmakeup",
    "soldejaneiro",
    "aliexpress",
    "aubureau",
    // "eiffage-logo",
    "fentybeauty",
    "fivepizza",
    "nars",
    "niceworkparis",
    "prozis",
];
